<template>
  <div class="warboard">
    <div class="map-container">
      <router-link to="/" id="navbar-fleet-manager">
        <div class="icon-container" >
          <img class="sidebar-logo" :src="sidebarIcon">
        </div>
      </router-link>
      <map-display v-bind:markers="markers" v-bind:viewport="viewPort"></map-display>
      <div class="config-button" @click="$bvModal.show('warboard-config-modal')">
        <i :class="$config.icons.warboard.config"></i>
      </div>
      <div v-if="showModes && currentMode === 'mapOverview'" class="overview-popup">
        <h1>Protekt Warboard</h1>
      </div>
      <div v-if="showModes && currentMode === 'statsOverview'" class="overview-popup">
        <h1>Vehicle Stats Today</h1>
      </div>
      <div v-if="showModes && statsData" class="device-stats-popup">
        <table>
          <tr>
            <th></th>
            <th>Device Name</th>
            <th>Avg Speed</th>
            <th>KMs Today</th>
            <th>Runtime Today</th>
            <th>Parked Today</th>
          </tr>
          <tr v-for="(stats, idx) of statsData" v-bind:key="idx">
            <td> <img :src="stats.icon"> </td>
            <td>{{stats.device_name}}</td>
            <td>{{stats.averageSpeed}} km/h</td>
            <td>{{stats.distance}} km</td>
            <td>{{stats.duration}}</td>
            <td>{{stats.parked}}</td>
          </tr>
        </table>
      </div>
      <div v-if="focusDevice" class="focus-device-popup">
        <table>
          <tr>
            <th>Device Name</th>
            <th>Device Code</th>
            <th>Speed</th>
            <th>Last Update</th>
          </tr>
          <tr>
            <td>{{focusDevice.device_name}}</td>
            <td>{{focusDevice.device_code}}</td>
            <td>{{focusDevice.location.speed}}</td>
            <td>{{this.currentDeviceTimestamp}}</td>
          </tr>
        </table>
      </div>
    </div>
    <!--   Config Modal-->
    <b-modal id="warboard-config-modal" centered class="modal-content" size="m" hide-footer title="Warboard Config">
      <div>
        <label class="green-label">Map Overview Time (milliseconds)</label>
        <b-form-input v-model="settings.modeTickSpeed.mapOverview" type="number"></b-form-input>
      </div>
      <div>
        <label class="green-label">Vehicle Stats Time (ms)</label>
        <b-form-input v-model="settings.modeTickSpeed.statsOverview" type="number"></b-form-input>
      </div>
      <div>
        <label class="green-label">Vehicle Stats Max Page Size</label>
        <b-form-input v-model="settings.statsPageSize" type="number"></b-form-input>
      </div>
      <div>
        <label class="green-label">Vehicle Focus Time (ms)</label>
        <b-form-input v-model="settings.modeTickSpeed.deviceFocus" type="number"></b-form-input>
      </div>
      <div>
        <label class="green-label">Vehicle Focus</label>
        <b-form-checkbox v-model="settings.onlyFocusMoving">Only Focus Moving Vehicles</b-form-checkbox>
      </div>
      <div class="mt-2 footer">
        <b-button class="button" @click="saveChanges()">Save Changes</b-button>
        <b-button class="button" @click="$bvModal.hide('warboard-config-modal')">Close</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import MapDisplay from '../components/helpers/MapDisplay'
import * as DataProvider from '@/components/helpers/DataProvider'
import * as ErrorHelper from '@/components/helpers/ErrorHelper'
import * as IconHelper from '@/components/helpers/IconHelper'
import TripsControl from '@/components/device/device_controls/TripsControl'
import moment from 'moment'

export default {
  name: 'fleet-view',
  components: {
    MapDisplay
  },
  data: function () {
    return {
      markers: [],
      viewPort: {
        zoom: 3,
        lat: 0,
        lng: 0
      },
      icons: IconHelper.IconLookup,
      settings: {
        statsPageSize: 10,
        modeTickSpeed: {
          mapOverview: 5000,
          deviceFocus: 5000,
          statsOverview: 10000
        },
        onlyFocusMoving: false
      },
      devices: [],
      devicesFocusList: [],
      isClosing: false,
      timerHandle: null,
      currentDeviceIdx: 0,
      focusDevice: null,
      currentModeIdx: 0,
      statsCurrentPage: 0,
      modes: {
        mapOverview: this.setMapOverviewMode,
        deviceFocus: this.setDeviceFocusMode,
        statsOverview: this.setStatsOverviewMode
      },
      modeList: [
        'mapOverview',
        'statsOverview',
        'deviceFocus'
      ],
      statsData: null
    }
  },
  async mounted () {
    if (localStorage.getItem('warboard_settings')) {
      try {
        this.settings = JSON.parse(localStorage.getItem('warboard_settings'))
        console.log('Settings Loaded: ', this.settings)
      } catch (e) {
        console.log('Failed to parse Warboard Settings!')
        console.error(e)
      }
    }
    this.$maps.hideMapControls()
    this.$maps.clearOverlays()
    this.devices = await this.getDeviceList()
    await this.updateDeviceLocations()
    console.log(this.devices)
    // Default to showing Map Overview when starting up
    this.setMapOverviewMode()
    this.startTimer()
  },
  beforeDestroy () {
    // Mark this component as closing so updates will be cancelled.
    this.isClosing = true
    clearInterval(this.timerHandle)
  },
  methods: {
    async getDeviceList () {
      let resp = await DataProvider.getDeviceList([
        'device_code',
        'device_name',
        'device_imei',
        'device_type',
        'location',
        'icon',
        'has_subscription'
      ])
      if (resp.success) {
        return DataProvider.objToArray(resp.data)
      } else {
        ErrorHelper.displayDataErrorToast(resp)
      }
    },
    async updateDeviceLocations () {
      let deviceMarkers = []
      let devices = this.devices.filter(x => x.has_subscription)
      for (let device of devices) {
        try {
          deviceMarkers.push({
            title: device.device_name,
            icon: device.icon,
            position: { lat: device.location.latitude, lng: device.location.longitude },
            link: 'device/' + device.device_imei
          })
          // This can take a while to complete, so it's possible the user will click on a device while we're waiting.
          // If they do cancel the update.
          if (this.isClosing) {
            console.log('Cancelled Fleet Screen Load')
            return
          }
        } catch (e) {
          console.log('Failed to get location for ' + device.device_code)
          console.error(e)
        }
      }
      console.log(deviceMarkers)
      this.$maps.updateMarkers(deviceMarkers, true)
    },
    async setFocusDevice (device) {
      console.log('Focusing: ', device)
      this.focusDevice = device
      if (device.location) {
        this.$maps.setViewport(device.location.latitude, device.location.longitude, 20)
      }
    },
    updateFocusList (devices) {
      if (this.settings.onlyFocusMoving) {
        this.devicesFocusList = devices.filter(x => x.location && x.location.data.speed > 0)
      } else {
        this.devicesFocusList = devices.filter(x => x.location)
      }
    },
    startTimer () {
      this.timerHandle = setTimeout(() => {
        this.tickTimer()
        console.log('Starting Timer, first timeout: ', this.settings.modeTickSpeed[this.currentMode])
      }, this.settings.modeTickSpeed[this.currentMode])
    },
    /***
     * Ticks the Warboard timer. Each tick will execute the 'setMode' function for the current mode, and if
     * it returns true, will set the mode to the next mode in the list. Modes which are displayed for a single tick,
     * should always return true, while multi-tick modes (like device focus) should return false, until they
     * are complete, then true, to move on to the next value in the list.
     */
    tickTimer () {
      if (!this.showModes) {
        // Don't show the models
        return
      }
      let nextMode = this.modes[this.modeList[this.currentModeIdx]]()
      let newMode = this.currentModeIdx
      if (nextMode && (this.currentModeIdx + 1 === this.modeList.length)) {
        newMode = 0
      } else if (nextMode) {
        newMode++
      }
      this.timerHandle = setTimeout(() => {
        this.currentModeIdx = newMode
        this.tickTimer()
        console.log('Setting Timeout: ', this.settings.modeTickSpeed[this.currentMode])
      }, this.settings.modeTickSpeed[this.currentMode])
    },
    setMapOverviewMode () {
      console.log('Setting Map Overview Mode')
      this.statsData = null
      this.focusDevice = null
      return true
    },
    setDeviceFocusMode () {
      console.log('Setting Device Focus')
      // First Execution, set the focus list from current data
      if (this.currentDeviceIdx === 0) {
        this.statsData = null
        this.updateFocusList(this.devices)
      }
      if (this.currentDeviceIdx === this.devicesFocusList.length) {
        this.getDeviceList().then((devices) => {
          console.log('Resetting focus list...')
          this.devices = devices
          this.updateDeviceLocations()
          this.updateFocusList(devices)
          this.focusDevice = null
          this.currentDeviceIdx = 0
        })
        return true
      } else {
        this.setFocusDevice(this.devicesFocusList[this.currentDeviceIdx])
        this.currentDeviceIdx++
        return false
      }
    },
    setStatsOverviewMode () {
      console.log('Setting Stats Overview')
      this.focusDevice = null
      this.statsData = []
      let statsComplete = false

      let tripDevices = this.devices.filter(device =>
        this.$deviceService.hasFeatureSync(device.device_type, 'device_trips')
      )
      // Trim to current page if required
      if (tripDevices.length > this.settings.statsPageSize) {
        // If the end of the max size of this page is smaller than the total we have another page to show
        statsComplete = ((this.statsCurrentPage + 1) * this.settings.statsPageSize) >= tripDevices.length
        tripDevices = tripDevices.slice(
          this.statsCurrentPage * this.settings.statsPageSize,
          Math.min((this.statsCurrentPage + 1) * this.settings.statsPageSize, tripDevices.length)
        )
        this.statsCurrentPage++
      }

      for (let device of tripDevices) {
        this.getCurrentRoute(device.device_imei).then(tripData => {
          let data = {
            device_name: device.device_name,
            averageSpeed: 0,
            distance: 0,
            duration: 0,
            parked: 0
          }
          if (tripData) {
            tripData = DataProvider.objToArray(tripData)
            data = TripsControl.methods.calculateStats(tripData)
            data.device_name = device.device_name
          }
          if (this.icons.hasOwnProperty(device.icon)) {
            data.icon = this.icons[device.icon]
          } else {
            data.icon = this.icons.Car
          }
          this.statsData.push(data)
        })
      }
      // reset stats paging at the end of the run
      if (statsComplete) {
        this.statsCurrentPage = 0
      }
      return statsComplete
    },
    getCurrentRoute: async function (deviceImei) {
      this.loading = true
      let startUTC = moment(moment(new Date()).format('YYYY-MM-DD') + ' 00:00').utc()
      let endUTC = moment(moment(new Date()).format('YYYY-MM-DD') + ' 23:59').utc()

      let response = await DataProvider.getDeviceTrips(deviceImei, startUTC, endUTC, 200)
      if (response.success) {
        return response.data.trips
      }
    },
    saveChanges () {
      localStorage.setItem('warboard_settings', JSON.stringify(this.settings))
      this.$bvModal.hide('warboard-config-modal')
      this.$router.go()
    }
  },
  computed: {
    showModes: function () {
      if (this.$route.query.showModes) {
        // Set in url, so use that value
        return this.$route.query.showModes === 'on'
      } else {
        // Not set, so show modes by default
        return true
      }
    },
    sidebarIcon: function () {
      if (process.env.VUE_APP_CONFIG_SHOW_BETA_ICON) {
        return require('../assets/Pro-tekt-logo-beta.png')
      } else {
        return require('../assets/Pro-tekt-logo.png')
      }
    },
    currentMode: function () {
      return this.modeList[this.currentModeIdx]
    },
    currentDeviceTimestamp () {
      if (this.focusDevice && this.focusDevice.location) {
        return moment.unix(this.focusDevice.location.timestamp).format('YYYY-MM-DD HH:mm')
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  @import '@/variables';

  .filter-box {
    display: grid;
    margin: 10px;
    padding: 10px;
    grid-auto-columns: 1fr;
    grid-column-gap: 10px;
    grid-row-gap: 8px;
    -ms-grid-columns: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    -ms-grid-rows: auto;
    grid-template-rows: auto;
    border-radius: 17px;
    background-color: $default-content-background;
  }

  .map-container {
    width: 100%;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%
  }

  .icon-container {
    z-index: 10;
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 10px;
    width: 150px;
    overflow: hidden;
  }

  .config-button {
    z-index: 10;
    position: absolute;
    font-size: 1.5em;
    border-radius: 2px;
    bottom: 14px;
    left: 20px;
    width: 40px;
    height: 40px;
    margin: 10px;
    overflow: hidden;
    background: white;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 4px -1px;
    cursor: pointer;
    text-align: center;
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
  }

  .focus-device-popup {
    z-index: 50;
    position: absolute;
    display: flex;
    flex-direction: row;
    bottom: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    //width: 200px;
    min-width: 80vw;
    //height: 100px;
    border-radius: 10px;
    background: $theme-color-background-4;
    color: $text-color-invert;
  }

  .device-stats-popup {
    z-index: 50;
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 110px;
    left: 50%;
    transform: translate(-50%, 0);
    //width: 200px;
    min-width: 80vw;
    //height: 100px;
    border-radius: 10px;
    background: $theme-color-background-4;
    color: $text-color-invert;
  }

  table {
    flex-grow: 1;
    font-size: 1.3em;
  }

  table tr td {
    border: 1px solid $theme-color-primary-2;
    padding: 5px;
    background: $theme-color-background-1
  }

  table tr th {
    border: 1px solid $theme-color-primary-2;
    padding: 5px;
  }

  table th {
    color: $theme-color-primary-2;
  }

  .overview-popup {
    position: absolute;
    display: flex;
    flex-direction: row;
    top: 1em;
    left: 50%;
    transform: translate(-50%, 0);
    border-radius: 10px;
    background: $theme-color-background-4;
    padding: 10px 20px;
  }

  .overview-popup h1 {
    font-weight: 600;
    color: $theme-color-primary-2;
  }

  .footer{
    width: 100%;
    bottom: 2%;
    display: flex;
    justify-content: flex-end;
    margin: 0 1% 0 1%;
  }

  @media screen and (max-width: 600px) {
    .map-container {
      width: calc(100% - 60px);
      right: 60px;
    }
    .sidebar-toggle {
      display: block;
    }
  }

</style>
