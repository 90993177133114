var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "warboard" },
    [
      _c(
        "div",
        { staticClass: "map-container" },
        [
          _c(
            "router-link",
            { attrs: { to: "/", id: "navbar-fleet-manager" } },
            [
              _c("div", { staticClass: "icon-container" }, [
                _c("img", {
                  staticClass: "sidebar-logo",
                  attrs: { src: _vm.sidebarIcon }
                })
              ])
            ]
          ),
          _c("map-display", {
            attrs: { markers: _vm.markers, viewport: _vm.viewPort }
          }),
          _c(
            "div",
            {
              staticClass: "config-button",
              on: {
                click: function($event) {
                  return _vm.$bvModal.show("warboard-config-modal")
                }
              }
            },
            [_c("i", { class: _vm.$config.icons.warboard.config })]
          ),
          _vm.showModes && _vm.currentMode === "mapOverview"
            ? _c("div", { staticClass: "overview-popup" }, [
                _c("h1", [_vm._v("Protekt Warboard")])
              ])
            : _vm._e(),
          _vm.showModes && _vm.currentMode === "statsOverview"
            ? _c("div", { staticClass: "overview-popup" }, [
                _c("h1", [_vm._v("Vehicle Stats Today")])
              ])
            : _vm._e(),
          _vm.showModes && _vm.statsData
            ? _c("div", { staticClass: "device-stats-popup" }, [
                _c(
                  "table",
                  [
                    _vm._m(0),
                    _vm._l(_vm.statsData, function(stats, idx) {
                      return _c("tr", { key: idx }, [
                        _c("td", [_c("img", { attrs: { src: stats.icon } })]),
                        _c("td", [_vm._v(_vm._s(stats.device_name))]),
                        _c("td", [
                          _vm._v(_vm._s(stats.averageSpeed) + " km/h")
                        ]),
                        _c("td", [_vm._v(_vm._s(stats.distance) + " km")]),
                        _c("td", [_vm._v(_vm._s(stats.duration))]),
                        _c("td", [_vm._v(_vm._s(stats.parked))])
                      ])
                    })
                  ],
                  2
                )
              ])
            : _vm._e(),
          _vm.focusDevice
            ? _c("div", { staticClass: "focus-device-popup" }, [
                _c("table", [
                  _vm._m(1),
                  _c("tr", [
                    _c("td", [_vm._v(_vm._s(_vm.focusDevice.device_name))]),
                    _c("td", [_vm._v(_vm._s(_vm.focusDevice.device_code))]),
                    _c("td", [_vm._v(_vm._s(_vm.focusDevice.location.speed))]),
                    _c("td", [_vm._v(_vm._s(this.currentDeviceTimestamp))])
                  ])
                ])
              ])
            : _vm._e()
        ],
        1
      ),
      _c(
        "b-modal",
        {
          staticClass: "modal-content",
          attrs: {
            id: "warboard-config-modal",
            centered: "",
            size: "m",
            "hide-footer": "",
            title: "Warboard Config"
          }
        },
        [
          _c(
            "div",
            [
              _c("label", { staticClass: "green-label" }, [
                _vm._v("Map Overview Time (milliseconds)")
              ]),
              _c("b-form-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.settings.modeTickSpeed.mapOverview,
                  callback: function($$v) {
                    _vm.$set(_vm.settings.modeTickSpeed, "mapOverview", $$v)
                  },
                  expression: "settings.modeTickSpeed.mapOverview"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("label", { staticClass: "green-label" }, [
                _vm._v("Vehicle Stats Time (ms)")
              ]),
              _c("b-form-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.settings.modeTickSpeed.statsOverview,
                  callback: function($$v) {
                    _vm.$set(_vm.settings.modeTickSpeed, "statsOverview", $$v)
                  },
                  expression: "settings.modeTickSpeed.statsOverview"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("label", { staticClass: "green-label" }, [
                _vm._v("Vehicle Stats Max Page Size")
              ]),
              _c("b-form-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.settings.statsPageSize,
                  callback: function($$v) {
                    _vm.$set(_vm.settings, "statsPageSize", $$v)
                  },
                  expression: "settings.statsPageSize"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("label", { staticClass: "green-label" }, [
                _vm._v("Vehicle Focus Time (ms)")
              ]),
              _c("b-form-input", {
                attrs: { type: "number" },
                model: {
                  value: _vm.settings.modeTickSpeed.deviceFocus,
                  callback: function($$v) {
                    _vm.$set(_vm.settings.modeTickSpeed, "deviceFocus", $$v)
                  },
                  expression: "settings.modeTickSpeed.deviceFocus"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            [
              _c("label", { staticClass: "green-label" }, [
                _vm._v("Vehicle Focus")
              ]),
              _c(
                "b-form-checkbox",
                {
                  model: {
                    value: _vm.settings.onlyFocusMoving,
                    callback: function($$v) {
                      _vm.$set(_vm.settings, "onlyFocusMoving", $$v)
                    },
                    expression: "settings.onlyFocusMoving"
                  }
                },
                [_vm._v("Only Focus Moving Vehicles")]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mt-2 footer" },
            [
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.saveChanges()
                    }
                  }
                },
                [_vm._v("Save Changes")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "button",
                  on: {
                    click: function($event) {
                      return _vm.$bvModal.hide("warboard-config-modal")
                    }
                  }
                },
                [_vm._v("Close")]
              )
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th"),
      _c("th", [_vm._v("Device Name")]),
      _c("th", [_vm._v("Avg Speed")]),
      _c("th", [_vm._v("KMs Today")]),
      _c("th", [_vm._v("Runtime Today")]),
      _c("th", [_vm._v("Parked Today")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("tr", [
      _c("th", [_vm._v("Device Name")]),
      _c("th", [_vm._v("Device Code")]),
      _c("th", [_vm._v("Speed")]),
      _c("th", [_vm._v("Last Update")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }