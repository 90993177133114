/*
    Google Map Display
    Displays a Google Map with custom markers
*/
<template>
  <div class="map-display">
  </div>
</template>

<script>
export default {
  name: 'map-display',
  props: {
    viewport: Object,
    markers: Array,
    geolimit: Object,
    routeData: Array
  },
  data: function () {
    return {
      map: null,
      markerManager: null,
      infoWindowOpen: null,
      mapDrawings: [],
      viewportInternal: { // NOTE: We have 'internal' data variables, as props should not be mutated
        lat: -37.69,
        lng: 144.76,
        zoom: 2
      },
      markersInternal: [],
      google: null,
      geolimitMarkers: [],
      geolimitInternal: null,
      markerColours: {
        red: 'https://maps.google.com/mapfiles/ms/icons/red.png',
        green: 'https://maps.google.com/mapfiles/ms/icons/green.png',
        blue: 'https://maps.google.com/mapfiles/ms/icons/blue.png',
        yellow: 'https://maps.google.com/mapfiles/ms/icons/yellow.png'
      },
      clusterer: null,
      clusterOpts: {
        gridSize: 150,
        minimumClusterSize: 3,
        maxZoom: 16,
      },
      routeDataInternal: []
    }
  },
  async mounted () {
    // Set the map to be displayed in this component's base element
    await this.$maps.createMap(this.$el)
  }
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
  @import '../../variables';

  .map-display {
    width: 100%;
    height: 100%;
  }

</style>
